// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import { pidListReducer, selectedPidReducer } from "./propertyBillControl";
import { pidFormReducer } from "./newPidForm";
import { newInvestorFormReducer } from "./newInvestorForm";
// import pidArray from "../../views/Pages/PropertyBillControl_v3/store/reducer";

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  pidListReducer,
  selectedPidReducer,
  // pidArray,
  pidFormReducer,
  newInvestorFormReducer,
});

export default rootReducer;

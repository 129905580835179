import { ActionTypes } from "../../constants/actionTypes";

const initialState = {
  inversionista: {},
  ctasBancarias: [],
};

export const newInvestorFormReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_NEWINVESTORFORM_INVERSIONISTA:
      return { ...state, inversionista: payload };
    case ActionTypes.SET_NEWINVESTORFORM_CTASBANCARIAS:
      return { ...state, ctasBancarias: payload };

    default:
      return state;
  }
};

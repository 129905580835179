export const ActionTypes = {
  SET_PIDLIST: "SET_PIDLIST",
  // SET_PIDLISTFILTER: "SET_PIDLISTFILTER",
  SET_CARDSDATA: "SET_CARDSDATA",
  SELECTED_PID: "SELECTED_PID",
  REMOVE_SELECTED_PID: "REMOVE_SELECTED_PID",

  SET_NEWINVESTORFORM_INVERSIONISTA: "SET_NEWINVESTORFORM_INVERSIONISTA",
  SET_NEWINVESTORFORM_CTASBANCARIAS: "SET_NEWINVESTORFORM_CTASBANCARIAS",

  SET_NEWPIDFORM_INVERSIONISTA: "SET_NEWPIDFORM_INVERSIONISTA",
  SET_NEWPIDFORM_PROPIEDADES: "SET_NEWPIDFORM_PROPIEDADES",
};
